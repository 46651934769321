import { BaseService } from '@estee/elc-service';
import { ServiceInitializer } from '@estee/elc-universal-utils';

const { name, version } = __serviceInfo__;
export class SessionManagementService extends BaseService {
    constructor(srvInitializer: ServiceInitializer) {
        super(name, version, srvInitializer);
    }
}

export default {
    signInApiSdk: 'SIGN_IN_API_SDK',
    configStore: 'CONFIG_STORE',
    signInRepository: 'SIGN_IN_REPOSITORY',
    httpTransport: 'HTTP_TRANSPORT',
    serviceBusController: 'SERVICE_BUS_CONTROLLER',
    sessionManagementService: 'SESSION_MANAGEMENT_SERVICE',
    authSession: 'AUTH_SESSION',
    careAuthSession: 'CARE_AUTH_SESSION',
    authSessionRepository: 'AUTH_SESSION_REPOSITORY',
    guestRepository: 'GUEST_REPOSITORY',
    guestUser: 'GUEST_USER',
    signUpFormStore: 'SIGN_UP_FORM_STORE',
    formValidator: 'FORM_VALIDATOR',
    emailVerificationStore: 'EMAIL_VERIFICATION_STORE'
};
