import { setupServiceInfo, getModuleFederatedName } from '@estee/elc-universal-utils';
import { ServiceManager } from '@estee/elc-service';
import serviceNames, { SessionManagementService } from '~setup/SessionManagementService';
import { serviceBusBaseClass } from '~setup/ServiceBus';

setupServiceInfo(__serviceInfo__);
const { name, version } = __serviceInfo__;

SessionManagementService.setViews(name, getModuleFederatedName(name), version, []);

ServiceManager.setServiceStartParams({
    serviceName: name,
    diContainerImport: () => import('~setup/diContainer'),
    serviceKey: serviceNames.sessionManagementService,
    serviceBusControllerKey: serviceNames.serviceBusController,
    serviceBus: {
        serviceBusBaseClass
    }
});
