import { ServiceManager, ServiceBusController, ServiceBusControllerBody } from '@estee/elc-service';
import {
    events,
    on,
    queryProvider,
    serviceQueries,
    commandProvider,
    serviceCommands
} from '@estee/elc-service-bus';

const queries = [
    [serviceQueries.IS_AUTHENTICATED, 'isAuthenticated'],
    [serviceQueries.GUEST_EMAIL, 'guestEmail'],
    [serviceQueries.GET_LEGACY_GUEST_AUTH_TOKEN, 'getLegacyGuestAuthToken'],
    [serviceQueries.GET_GUEST_AUTH_TOKEN, 'getGuestAuthToken'],
    [serviceQueries.GET_ACCESS_TOKEN, 'getAccessToken'],
    [serviceQueries.USER_ID, 'getUserId']
];
const commands = [
    [serviceCommands.AUTH_UPDATE_DATA, 'updateAccessToken'],
    [serviceCommands.SET_SIGN_IN_EMAIL, 'setFormEmail']
];
const onEvents = [[events.SIGNED_OUT, 'signedOut']];

export class ServiceBus extends ServiceBusController {
    @on(events.ECOMM_STARTED, { replay: true })
    public onEcommStarted = () => {
        const id = setInterval(() => {
            // eslint-disable-next-line @typescript-eslint/no-use-before-define
            if (serviceBusBaseClass) {
                clearInterval(id);
                queries.forEach(ServiceManager.setDecoratorOnFunction(queryProvider));
                onEvents.forEach(ServiceManager.setDecoratorOnFunction(on));
                commands.forEach(ServiceManager.setDecoratorOnFunction(commandProvider));
            }
        }, 50);
    };
}

export const serviceBusBaseClass = new ServiceBus() as ServiceBusControllerBody;
